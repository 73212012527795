import React, { useState } from "react";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={3}
        lesson={"Lesson 4"}
        color={"ms"}
        total_count={15}
        module_title={undefined}
      >
        <ContentWrapper>
          <H1 underline>Step 3</H1>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>How does the type of information in these ads compare with the type of information you would include with the product you wanted to sell (at the beginning of this lesson)?</p>
              <p>Think about the following questions and discuss them with your teammates.</p>

              <UnorderedList>
                <li>Can people who write these commercials (or ads) say anything they want?</li>
                <li>Why would commercials include information about what could go wrong if someone takes it?</li>
              </UnorderedList>

              <p>Be ready to participate in a class discussion.</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Students compare their ideas from watching the commercials with their ideas about what they would do when advertising their own product. Many students would not have included anything negative in what they listed for their own product (in step 1). They also consider questions about whether advertisers can say anything they want about their products and why advertisers would include possible negative effects. Ask students to share their ideas in a brief class discussion of these questions.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;

